<template>
  <div id="app">
    <keep-alive>
      <router-view
        v-if="$route.meta.keepAlive"
        class="router-view keep"
      ></router-view>
    </keep-alive>
    <router-view
      v-if="!$route.meta.keepAlive"
      class="router-view"
    ></router-view>
    <!-- 全局修改密码 -->
    <ChangePassword
      :isShow="isShowPassword"
      :mobile="baseMobile"
      @updatePage="getInit"
    ></ChangePassword>
    <changeLogin></changeLogin>
  </div>
</template>

<script>
import ChangePassword from '@/components/ChangePass.vue'
import changeLogin from '@/components/changeLogin'
import { getItem } from '@/assets/js/storage.js'
export default {
  components: {
    ChangePassword,
    changeLogin
  },
  data() {
    return {
      isShowPassword: 1,
      baseMobile: '刷新重新获取手机号'
    }
  },
  mounted() {
    let userInfo = getItem('user')
    this.baseMobile = userInfo.mobile ? userInfo.mobile : ''
    this.$bus.$on('changePassM', this.changeHandle)
  },
  methods: {
    // 修改成功的回调
    getInit() {
      this.$message.success('修改成功')
      setTimeout(() => {
        this.$router.replace({ name: 'login' })
      }, 2000)
    },
    // 点击显示更换手机 ,密码
    changeHandle(type) {
      if (type === 'password') {
        this.isShowPassword += 1
      }
    }
  }
}
</script>
<style lang="scss">
@import url('./assets/css/reset.css');
@import url('./assets/css/common.css');
#app {
  text-align: center;
  color: #2c3e50;
}
.noMsg {
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  z-index: 0;
  .img {
    width: 442px;
    margin: 0 auto;
    font-size: 0;
    img {
      width: 100%;
    }
  }
}
</style>
