const events = []

const $scroll = function (dom, fn) {
  events.push({ dom, fn })
  fn._index = events.length - 1
}

$scroll.remove = function (fn) {
  fn._index && events.splice(fn._index, 1)
}

// 上拉加载
const Scroll = {
  addHandler: function (element, type, handler) {
    if (element.addEventListener) {
      element.addEventListener(type, handler, false)
    } else if (element.attachEvent) {
      element.attachEvent('on' + type, handler)
    } else {
      element['on' + type] = handler
    }
  },
  listenTouchDirection: function () {
    setTimeout(() => {
      var domSt = null
      var domSh =null
      var domS =null
      if(events.length > 0) {
        events.filter(e => {
          return e.dom.scrollHeight && e.dom.scrollHeight > 0
        }).forEach(e => {
          this.addHandler(e.dom, 'scroll', function () {
            domSt = e.dom.scrollTop  
            domSh = e.dom.scrollHeight 
            domS = e.dom.clientHeight  
            var s = Math.ceil((domS / (domSh - domSt)) * 100)
            if (s > 85) {
              e.fn()
            }
          })
        })
      }
    },200)
  }
}

Scroll.listenTouchDirection()

export default $scroll
export { Scroll }
