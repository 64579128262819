export default[
  {
    path: "/stuff/user",
    name: "user",
    meta:{
      title: '个人设置',
      auth: false,
    },
    component: () => import("../../views/user/userIndex.vue")
  },
  {
    path: "/stuff/inform",
    name: "userInform",
    meta:{
      title: '站内信',
      auth: false,
    },
    component: () => import("../../views/user/inform.vue")
  },
  {
    path: "/stuff/inform/detail",
    name: "userDetail",
    meta:{
      title: '站内信详情',
      auth: false,
    },
    component: () => import("../../views/user/informDetail.vue")
  },
  
]
