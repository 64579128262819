import axios from 'axios';
import qs from 'qs';
import { Message } from 'element-ui'
const isDev = process.env.NODE_ENV === 'development'
const http = axios.create({
  baseURL: isDev ? '' :  exporeConstNoChange.apiHttps,
  // baseURL: process.env.BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'X-Requested-With': 'XMLHttpRequest',
    'Client-Type': 'MALL'
  },
  responseType: 'json'
})

// 请求拦截器
http.interceptors.request.use(config => {
  if(config.form){
    config.responseType = 'arraybuffer'
  }
  // config.header.Token = 'xxxxxx';
  config.data = qs.stringify(config.data, { arrayFormat: 'repeat' })
  return config;
}, err => {
  return err;
})

// 响应拦截器
http.interceptors.response.use(res => {
  if(res.status === 200){
    var data = res.data;
    if(data.code == 0){
      return data;
    }else{
      if(res.config.params && res.config.params.checkCan){
        return data;
      }
      if(res.config.responseType == 'arraybuffer'){
        return data;
      }else{
        Message.error(data.msg);
      }
      
      return data;
    }
  }
}, err => {
  Message.error('错误码status：' + err.response.status)
})

// function request(http){
//   const obj = {
//     url: http.url,
//     data: http.data || {},
//     method: http.method,
//   }
//   if (http.form) {
//     obj.responseType = 'arraybuffer'
//   } else {
//     obj.responseType = 'json'
//   }
//   return obj
// }
export default http