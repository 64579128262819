import Vue from 'vue'
import Vuex from 'vuex'
import { get } from '../api/index.js'
import { getItem } from '../assets/js/storage.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stateNum: 0,
    stateUser: ''
  },
  mutations: {
    setNumModal(state, status) {
      state.stateNum = status
    },
    setUserModal(state, status) {
      state.stateUser = status
    },
  },
  actions: {
    setNumModal(store) {
      var params = {
        page: 1,
        pagesize: 40
      }
      get('/api/project_camp/joinList', params).then(res => {
        var data = res.data.list;
        store.commit('setNumModal', data.length)
      })
    },
    setUserModal({ commit }){
      commit('setUserModal', getItem('user'))
    }
  },
  modules: {
  }
})
