<template>
  <div style="position: fixed; z-index: 9999;" v-if="show">
    <div class="mask"></div>
    <div class="modal">
      <div class="prst">
        <div class="boxs">
          <div class="tcPa">
            <div class="tc title f36 pt80">请先登录</div>
            <div class="tc dest f26">先登陆才能查看课程内容哦~</div>
            <div class="ok f38 cup" @click="cancelJoin">登录</div>
            <div class="letter cup" @click="close">稍后再说</div>
          </div>
          <div class="img33">
            <img src="../assets/img/img33.png" alt />
          </div>
        </div>
        <div class="img34">
          <img src="../assets/img/img34.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      show: false
    };
  },
  mounted() {
    this.$bus.$on('isLogin', this.open);
  },
  methods: {
    close(){
      this.show = false;
    },
    open(){
      this.show = true;
    },
    cancelJoin(){
      var query = [{
        path: this.$route.path,
        query: this.$route.query
      }]
      var str = JSON.stringify(query);
      this.$router.push('/login?str='+str);
      this.show = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  width: 338px;
  height: 318px;
  background-color: #ffffff;
  border-radius: 20px;
  .prst {
    position: relative;
    width: 100%;
    height: 100%;
    .boxs {
      position: relative;
      background-color: #ffffff;
      width: 100%;
      height: 100%;
      z-index: 3;
    }
    .img34 {
      width: 260px;
      height: 171px;
      position: absolute;
      left: -200px;
      bottom: -42px;
      z-index: 2;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .img33 {
      width: 255px;
      height: 395px;
      position: absolute;
      left: -210px;
      top: -55px;
      z-index: 4;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .tcPa {
      text-align: center;
      .letter {
        text-align: center;
        font-size: 14px;
        color: #999999;
        position: absolute;
        left: 50%;
        bottom: 24px;
        transform: translateX(-50%);
        z-index: 3;
      }
      .ok {
        position: absolute;
        left: 50%;
        bottom: 50px;
        transform: translateX(-50%);
        z-index: 3;
        width: 150px;
        height: 48px;
        border-radius: 48px;
        line-height: 48px;
        background-color: #5d69fb;
        color: #ffffff;
        font-size: 26px;
      }
      .tc {
        color: #5d69fb;
        &.dest {
          padding-top: 20px;
          font-size: 20px;
        }
        &.title {
          font-weight: bold;
        }
      }
    }
  }
}
.mask {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>