import http from '../config/http.js'

export const get = (url, data, form) => {
  return http({
    url: url,
    method: "get",
    params: data || {},
    form
  })
}

export const post = (url, data) => {
  return http({
    url: url,
    method: "post",
    data
  })
}