import Vue from 'vue'
import VueRouter from 'vue-router'
import model from './model';
import { getItem } from '../assets/js/storage.js';

Vue.use(VueRouter)
 
const routes = [...model]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition && to.meta.scroll) {
      to.meta.scrollTop = savedPosition.y
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  var user = getItem('user');
  if(to.meta.auth){
    if(user){
      next()
    }else{
      if(to.path === '/login'){
        next()
      }else{
        next('/login')
      }
    }
  }else{
    next()
  }
  next();
})
// 解决Vue-Router升级导致的Uncaught(in promise) navigation guard问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}


export default router
