<template>
  <div class="add-child">
    <el-dialog
      title="修改登录密码"
      :visible.sync="ischangeMobile"
      width="540px"
      :before-close="resetM"
    >
      <div class="form-box">
        <el-form
          ref="changeMobileForm"
          :model="changeMobileForm"
          :rules="changeMobileRules"
        >
          <el-form-item label="" prop="mobile">
            <div class="item">
              <el-input
                v-model="changeMobileForm.mobile"
                :disabled="isDisabled"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" prop="codeImg">
            <div class="item code-box">
              <el-input
                v-model="changeMobileForm.codeImg"
                placeholder="图形验证码"
              ></el-input>
              <p class="codeImg" @click="imgCodeHandle">
                <img :src="imgCode" alt="" />
              </p>
            </div>
          </el-form-item>
          <el-form-item label="" prop="code">
            <div class="item">
              <el-input
                v-model="changeMobileForm.code"
                placeholder="短信验证码"
              ></el-input>
              <span
                class="code cursor"
                :class="isCountDown ? 'no-click-gray' : ''"
                @click="sendMoblieCodeHandle"
                >{{ codeTip }}</span
              >
            </div>
          </el-form-item>
          <el-form-item label="" prop="password">
            <div class="item">
              <el-input
                v-model="changeMobileForm.password"
                placeholder="填写新密码"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item label="" prop="repassword">
            <div class="item">
              <el-input
                v-model="changeMobileForm.repassword"
                placeholder="确认新密码"
              ></el-input>
            </div>
          </el-form-item>
          <el-form-item>
            <div class="btn fcenter" @click="submitFormChangeMobile('sure')">
              确认修改
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getCodeApi, checkEditMobileApi, setPasswordApi } from '@/api/user'
export default {
  props: {
    mobile: [String, Number],
    isShow: [Number]
  },
  data() {
    var validateMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else {
        if (!reg_tel.test(value)) {
          callback(new Error('请输入正确手机号码'))
        }
        callback()
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (value.length < 6) {
          callback(new Error('密码不能少于6位数'))
        }
        if (this.changeMobileForm.repassword !== '') {
          this.$refs.changeMobileForm.validateField('repassword')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.changeMobileForm.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    return {
      codeTip: '获取验证码',
      isCountDown: false,
      imgCode: '',
      ischangeMobile: false,
      dialogFormVisible: false,
      isDisabled: false,
      // 更换手机的
      changeMobileForm: {
        mobile: '',
        code: '',
        codeImg: '',
        password: '',
        repassword: ''
      },
      changeMobileRules: {
        mobile: [
          {
            required: true,
            validator: validateMobile,
            message: '请填写正确手机号码',
            trigger: 'change'
          }
        ],
        code: [{ required: true, message: '请填写验证码', trigger: 'change' }],
        password: [{ validator: validatePass, trigger: 'blur' }],
        repassword: [{ validator: validatePass2, trigger: 'blur' }],
        codeImg: [
          { required: true, message: '请填写图形码', trigger: 'change' }
        ]
      }
    }
  },
  mounted() {},
  methods: {
    //获取图形验证码
    async imgCodeHandle() {
      var params = {
        w: 105,
        h: 43,
        t: this.$util.random(1000, 9999)
      }
      var res = await this.$api.get('/app/captcha/sendSms', params, {
        responseType: 'arraybuffer'
      })
      var data =
        'data:image/png;base64,' +
        btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )
      this.imgCode = data
    },
    // 获取验证码
    sendMoblieCodeHandle() {
      if (!this.changeMobileForm.codeImg) {
        this.$message.error('请填写图形验证码')
        return
      }
      if (this.isDisabled) {
        getCodeApi({
          t: 'checkMobile',
          smsCaptchaCode: this.changeMobileForm.codeImg
        }).then(res => {
          if (res.code === 0) {
            this.isCountDown = true
            this.codeTip = '60s重发'
            var currentTime = 60
            var interval = setInterval(() => {
              currentTime--
              this.codeTip = currentTime + 's重发'
              if (currentTime <= 0) {
                clearInterval(interval)
                this.codeTip = '获取验证码'
                this.isCountDown = false
                this.imgCodeHandle()
              }
            }, 1000)
          } else {
            this.imgCodeHandle()
            // this.$message.error(res.msg)
          }
        })
      } else {
        this.checkEditMobileApiM()
      }
    },
    // 更换手机处理
    submitFormChangeMobile() {
      this.$refs.changeMobileForm.validate(valid => {
        if (valid) {
          // 下一步

          // 确认修改
          this.setMobileApiM()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 验证找回密码
    checkEditMobileApiM() {
      checkEditMobileApi({
        mobile: this.changeMobileForm.mobile,
        t: 'findpwd',
        smsCaptchaCode: this.changeMobileForm.codeImg
      }).then(res => {
        if (res.code === 0) {
          console.log('获取成功')
        }
      })
    },
    // 设置新密码
    setMobileApiM() {
      setPasswordApi({
        password: this.changeMobileForm.password,
        repassword: this.changeMobileForm.repassword,
        code: this.changeMobileForm.code
      }).then(res => {
        if (res.code === 0) {
          // 修改成功
          this.ischangeMobile = false
          this.$emit('updatePage')
        }
      })
    },
    resetM() {
      this.$refs['changeMobileForm'].resetFields()
      this.ischangeMobile = false
    }
  },
  watch: {
    isShow() {
      this.ischangeMobile = true
      this.changeMobileForm.mobile = this.mobile
      this.isDisabled = true
      this.imgCodeHandle()
    }
  }
}
</script>

<style scoped lang="scss">
.el-input.is-disabled .el-input__inner {
  background-color: none;
}
.add-child {
  /deep/ .el-dialog {
    border-radius: 20px;
    .el-dialog__header {
      text-align: left;
    }
  }
  .el-icon-close:before {
    font-size: 26px;
  }
  .form-box {
    padding: 0 80px;
    .el-input__inner,
    .el-input {
      background-color: none;
      border: none;
      width: 100%;
      font-size: 14px;
      color: #333;
    }
    .item {
      height: 46px;
      display: flex;
      align-items: center;
      border-radius: 31px;
      border: solid 1px #d9d9d8;
      padding: 10px;
      box-sizing: border-box;
      position: relative;
      .el-input.is-disabled .el-input__inner {
        background-color: none;
      }

      .icon {
        position: absolute;
        width: 31px;
        height: 22px;
        background: url(~img/login/not-see.png) no-repeat;
        right: 30px;
        top: 20px;
        cursor: pointer;
        &.cur {
          background: url(~img/login/see.png) no-repeat;
        }
      }
      .code {
        position: absolute;
        padding: 10px;
        right: 30px;
        font-size: 14px;
        color: #6672fb;
      }
      .el-select {
        width: 100%;
      }
    }
    .code-box {
      display: flex;
      .codeImg {
        width: 200px;
        height: 170%;
      }
    }
    .btn {
      height: 46px;
      background-color: #6672fb;
      border-radius: 31px;
      font-size: 14px;
      margin-top: 30px;
      color: #ffffff;
    }
  }
}
/deep/.el-input__inner,
.el-input {
  background-color: none;
  border: none;

  width: 100%;
  font-size: 14px;
  color: #333;
}
</style>
