export default[
  {
    path: "/login",
    name: "login",
    meta:{
      title: '登录',
      auth: false,
    },
    component: () => import("../../views/login/login.vue")
  },
  {
    path: "/wxlogin",
    name: "wxlogin",
    meta:{
      title: '微信登录',
      auth: false,
    },
    component: () => import("../../views/login.vue")
  },
  {
    path: "/register",
    name: "register",
    meta:{
      title: '注册',
      auth: false,
    },
    component: () => import("../../views/login/register.vue")
  },
  {
    path: "/forget",
    name: "forget",
    meta:{
      title: '忘记密码',
      auth: false,
    },
    component: () => import("../../views/login/forget.vue")
  }
]
