import home from './home.js';
import login from './login.js';
const notFound = [{
    path: "/404",
    name: '404',
    component: () => import("../../views/404.vue")
  },
  {
    path: '/',
    redirect: {
      name: "home"
    }
  },
  {
    path: '/videoPpt',
      name: "videoPpt",
      component: () => import("@/views/user/videoPpt.vue")
     
  },
  {
    path: '*', // vue2可以使用 * ，vue3使用/:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
    redirect: {
      name: "404"
    }
  }
]
export default [...login, ...home, ...notFound]
