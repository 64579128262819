import Vue from 'vue'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/css/user.scss'
 
import App from './App.vue'
import router from './router'
import store from './store'
import * as api from './api/index.js'
import util from './config/utils.js'
import throttle from './config/throttle.js'
import $scroll from '@/utils/loading'

Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$api = api;
Vue.prototype.$util = util;
Vue.prototype.$throttle = throttle;
Vue.prototype.$scroll = $scroll

new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate(){
    Vue.prototype.$bus = this
  }
}).$mount('#app')
