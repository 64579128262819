import user from './user.js';
export default[
  {
    path: "/",
    name: "/",
    redirect:'/home',
    meta:{
      title: 'Teacher world',
      keepAlive: true,
      scroll: true
    },
    component: () => import("../../views/home.vue"),
    children: [
      {
        path: "/home",
        name: "home",
        meta:{
          title: 'Teacher world',
          keepAlive: false,
          auth: false,
          scroll: true
        },
        component: function () {
          return import( '../../views/home/home.vue')
        }
      },
      {
        path: "/home/choice",
        name: "home-choice",
        meta:{
          title: '课程合集',
          keepAlive: false,
          auth: false,
          scroll: true
        },
        component: function () {
          return import( '../../views/home/choice.vue')
        }
      },
      {
        path: "/home/more",
        name: "home-more",
        meta:{
          title: '更多课程列表',
          keepAlive: false,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/home/more.vue")
      },
      {
        path: "/home/video",
        name: "home-video",
        meta:{
          title: '查看完整视频',
          keepAlive: true,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/home/video.vue")
      },
      {
        path: "/home/detail",
        name: "home-detail",
        meta:{
          title: '活动详情',
          keepAlive: false,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/home/detail.vue")
      },
      {
        path: "/home/list",
        name: "home-list",
        meta:{
          title: '课程列表',
          keepAlive: true,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/home/list.vue")
      },
      {
        path: "/home/courser",
        name: "home-courser",
        meta:{
          title: '课程详情',
          keepAlive: false,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/home/courser.vue")
      },
      {
        path: "/course",
        name: "course",
        meta:{
          title: '我的课程',
          keepAlive: true, // 是否进行缓存页面
          auth: false,   // 需要登录才能访问
          scroll: true, // 返回当前页时，是否保存滚动位置
        },
        component: () => import("../../views/course.vue")
      },
      {
        path: "/course/detail",
        name: "course-detail",
        meta:{
          title: '我的课程',
          keepAlive: false, // 是否进行缓存页面
          auth: false,   // 需要登录才能访问
          scroll: true, // 返回当前页时，是否保存滚动位置
        },
        component: () => import("../../views/course/detail.vue")
      },
      {
        path: "/stuff",
        name: "stuff",
        meta:{
          title: '课程资料库',
          keepAlive: true,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/stuff.vue")
      },
      {
        path: "/stuff/list",
        name: "stuff-list",
        meta:{
          title: '课程资料库',
          keepAlive: true,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/stuff/list.vue")
      },
      {
        path: "/stuff/search",
        name: "stuff-search",
        meta:{
          title: '课程资料库',
          keepAlive: true,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/stuff/search.vue")
      },
      {
        path: "/stuff/detail",
        name: "stuff-detail",
        meta:{
          title: '课程资料库',
          keepAlive: false,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/stuff/detail.vue")
      },
      {
        path: "/home/activity",
        name: "home-activity",
        meta:{
          title: '我的活动报名',
          keepAlive: true,
          auth: false,
          scroll: true
        },
        component: () => import("../../views/home/activity.vue")
      },
      // 个人中心相关的
      ...user
    ]
  },
]
