import { post, get } from './index';

// 个人中心
export const userIndex = (data) => {
  return post('/api/member_center/index', data);
}

// 个人中心 - 设置 - 修改个人资料
export const setUserInfo = (data) => {
  return post('/api/member_setting/editCustomer', data);
}

// 机构 - 账号管理 - 子账号列表
export const storeEmployeeList = (data) => {
  return get('/api/store_employee/index', data);
}

// 机构 - 账号管理 - 部门列表(账号类别)
export const departmentList = (data) => {
  return get('/api/store_employee/department', data);
}

// 通用 - 短信验证码  不需要图形验证码
export const sendCodeApi = (data) => {
  return post('/api/store_employee/sendAddMobileCode', data);
}


// 机构 - 账号管理 - 添加子账号
export const addOrgApi = (data) => {
  return get('/api/store_employee/add', data);
}

// 机构 - 账号管理 - 修改子账号状态
export const editOrgChildApi = (data) => {
  return post('/api/store_employee/changeState', data);
}

// 机构 - 账号管理 - 删除子账号
export const deleteOrgChildApi = (data) => {
  return post('/api/store_employee/del', data);
}

// 个人 设置个人资料
export const changePersonInfoApi = (data) => {
  return post('/api/member_setting/editCustomer', data);
}

// 个人信息
export const personInfoApi = (data) => {
  return post('/api/member_setting/index', data);
}

// 图形验证码
export const getCodeApi = (data) => {
  return post('/api/common/sendSmsCode', data);
}

// 通用 - 短信验证码 - 验证用户手机号(支付密码、登录密码等)
export const checkMobileApi = (data) => {
  return post('/api/common/mobile', data);
}

// 通用 - 短信验证码 - 发送手机验证码——需图形验证码)
export const checkEditMobileApi = (data) => {
  return post('/api/common/sendSmsCodeWithCaptcha', data);
}

// 个人中心 - 设置 - 修改手机号码
export const setMobileApi = (data) => {
  return post('/api/member_setting/modifyMobile', data);
}

// 个人中心 - 设置 - 修改密码(通过验证码单页面一步进行)
export const setPasswordApi = (data) => {
  return post('/api/member_setting/onestepPassword', data);
}

// 个人中心 - 消息 - 信息列表
export const systemInfoListApi = (data) => {
  return get('/api/member_message/sys', data);
}

// 个人中心 - 消息 - 信息详情
export const systemInfoDetailApi = (data) => {
  return get('/api/member_message/sysInfo', data);
}

// 个人中心 - 消息 - 删除消息
export const systemInfoDeleteApi = (data) => {
  return get('/api/member_message/del', data);
}

// 个人中心 - 消息 - 设为已读
export const setReadedApi = (data) => {
  return get('/api/member_message/read', data);
}

// 个人中心 - 消息 - 获取系统消息未读数量
export const getNoReadedApi = (data) => {
  return get('/api/member_message/countSys', data);
}

// 课程详情
export const courseInfoApi = (data) => {
  return get('/api/project_course/courseInfo', data);
}